var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',{attrs:{"valid":_vm.selected.length > 0,"title":"Add/Remove Project User","max-width":"800","confirm-label":"Save Changes"},on:{"confirmed":_vm.handleSave,"cancelled":_vm.handleCancel},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-item-group',{attrs:{"multiple":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-row',_vm._l((_vm.allusersExceptCurrentAdmin),function(item){return _c('v-col',{key:item.id,attrs:{"cols":_vm.mdUp ? 2 : 6}},[_c('v-item',{attrs:{"value":item.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"xflex xflex-col xjustify-center xtext-center xitems-center"},[_c('v-badge',{attrs:{"bordered":"","color":active ? "primary" : 'white',"icon":active
                        ? 'mdi-check-bold'
                        : 'mdi-checkbox-blank-circle-outline',"overlap":""}},[_c('v-avatar',{staticClass:"xmask xmask-squircle",class:hover ? 'xopacity-90' : '',attrs:{"tile":"","size":"5em"}},[_c('v-img',{staticClass:"text-right pa-2 xcursor-pointer",attrs:{"contain":"","src":item.image_url},on:{"click":toggle}})],1)],1),_c('span',[_vm._v(_vm._s(item.real_name))])],1)]}}],null,true)})]}}],null,true)})],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }