<template>
  <v-sheet
    class="xflex xw-full md:xflex-row xflex-col xgap-[3px] xjustify-between xitems-center"
  >
    <div
      :class="[mdUp ? 'xflex-row' : 'xflex-col']"
      class="controller typer xflex xjustify-start xgap-[5px] xitems-center xw-full"
    >
      <KirbyDatePicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-if="type === 'daily'"
      ></KirbyDatePicker>
      <KirbyWeeklyPicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'weekly'"
      ></KirbyWeeklyPicker>
      <KirbyMonthPicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'monthly'"
      ></KirbyMonthPicker>
      <div
        v-if="laptopUp"
        :class="[mdUp ? '' : 'xw-full']"
        class="xflex xflex-row md:xjustify-start xjustify-center xitems-center xgap-[5px]"
      >
        <btn-tooltip
          tip="Filter by date"
          :color="type === 'daily' ? '#7A38A3' : 'base_100'"
          :disabled="type === 'daily'"
          :outlined="type !== 'daily'"
          @click="setFilter('daily')"
          class="text-none"
        >
          Day
        </btn-tooltip>
        <btn-tooltip
          tip="Filter by weekly"
          :color="type === 'weekly' ? '#7A38A3' : 'base_100'"
          :disabled="type === 'weekly'"
          :outlined="type !== 'weekly'"
          @click="setFilter('weekly')"
          class="text-none"
        >
          Week
        </btn-tooltip>
        <btn-tooltip
          tip="Filter by monthly"
          :color="type === 'monthly' ? '#7A38A3' : 'base_100'"
          :disabled="type === 'monthly'"
          :outlined="type !== 'monthly'"
          @click="setFilter('monthly')"
          class="text-none"
        >
          Month
        </btn-tooltip>
      </div>
      <div
        class="xflex xflex-row xjustify-end xitems-center xgap-[5px] xw-full !xmy-[5px]"
        v-else
      >
        <btn-range-filter
          @monthly="setFilter('monthly')"
          @weekly="setFilter('weekly')"
          @daily="setFilter('daily')"
          :type="type"
          no-custom
        >
        </btn-range-filter>
        <v-spacer></v-spacer>
        <btn-list
          icon="mdi-chevron-down"
          btn-color="primary"
          text-color="primary"
          outlined
        >
          <template v-slot:label>
            <v-icon small color="primary">mdi-form-dropdown</v-icon>
          </template>
          <v-list>
            <v-list-item
              :disabled="!project"
              @click="project_users_modal = true"
            >
              <v-list-item-title>Add/Remove User</v-list-item-title>
            </v-list-item>
          </v-list>
        </btn-list>
        <btn-export
          v-if="hasDownload"
          :api-csv="csvUrl"
          :filename="fileName"
        ></btn-export>
        <!-- <btn-export
          v-if="hasDownload"
          :api-csv="csvUrl"
          :api-pdf="pdfUrl"
          :api-jpg="jpgUrl"
          :filename="fileName"
        ></btn-export> -->
      </div>
    </div>
    <div
      v-if="laptopUp"
      class="xflex xw-full xgap-[0.5em] md:xflex-row xflex-col xjustify-end xitems-center"
    >
      <btn-tooltip
        :disabled="!project"
        @click="project_users_modal = true"
        tip="Add or Remove Project Users"
        color="primary"
        :block="smDown"
        class="text-none"
      >
        <v-icon left>mdi-plus-minus</v-icon> Add/Remove User
      </btn-tooltip>

      <btn-export
        v-if="hasDownload"
        :api-csv="csvUrl"
        :filename="fileName"
        :block="smDown"
      ></btn-export>

      <!-- <btn-export
        v-if="hasDownload"
        :api-csv="csvUrl"
        :api-pdf="pdfUrl"
        :api-jpg="jpgUrl"
        :filename="fileName"
        :block="smDown"
      ></btn-export> -->
      <slot name="extra-buttons"></slot>
    </div>

    <ProjectUsersModal
      v-model="project_users_modal"
      :project="project"
      @updated="$emit('project-users-updated')"
    ></ProjectUsersModal>
  </v-sheet>
</template>

<script>
import moment from "moment";
import KirbyWeeklyPicker from "@/views/GlobalComponents/Forms/KirbyWeeklyPicker.vue";
import KirbyDatePicker from "@/views/GlobalComponents/Forms/KirbyDatePicker.vue";
import KirbyMonthPicker from "@/views/GlobalComponents/Forms/KirbyMonthPicker.vue";
import { mapGetters } from "vuex";
import ProjectUsersModal from "@/views/GlobalComponents/Modals/ProjectUsersModal.vue";
export default {
  props: {
    type: { type: String, default: "daily" },
    value: { type: [Object, Array, String], default: undefined },
    hasDownload: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedValue: null,
      project_users_modal: false,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedValue = val;
      },
      immediate: true,
      deep: true,
    },
    selectedValue: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("projects", ["project"]),
    fileName() {
      if (this.type === "monthly")
        return `${this.project.name}-Monthly-${this.value}`;
      else if (this.type === "weekly")
        return `${this.project.name}-Weekly-${this.value[0]}-${this.value[1]}`;
      else return `${this.project.name}-Daily-${this.value}`;
    },
    csvUrl() {
      if (this.type === "monthly")
        return `api/project-users/${this.projectId}/monthly/${this.value}/null/csv`;
      else if (this.type === "weekly")
        return `api/project-users/${this.projectId}/weekly/${this.value[0]}/${this.value[1]}/csv`;
      else
        return `api/project-users/${this.projectId}/daily/${this.value}/null/csv`;
    },
    pdfUrl() {
      if (this.type === "monthly")
        return `api/project-users/${this.projectId}/monthly/${this.value}/null/pdf`;
      else if (this.type === "weekly")
        return `api/project-users/${this.projectId}/weekly/${this.value[0]}/${this.value[1]}/pdf`;
      else
        return `api/project-users/${this.projectId}/daily/${this.value}/null/pdf`;
    },
    jpgUrl() {
      if (this.type === "monthly")
        return `api/project-users/${this.projectId}/monthly/${this.value}/null/jpg`;
      else if (this.type === "weekly")
        return `api/project-users/${this.projectId}/weekly/${this.value[0]}/${this.value[1]}/jpg`;
      else
        return `api/project-users/${this.projectId}/daily/${this.value}/null/jpg`;
    },
    projectId() {
      return this.$route.params.project_id || null;
    },
  },
  methods: {
    emitChange(val) {
      this.$emit("change", val);
    },
    emitSearch(val) {
      this.$emit("search", { keyword: val, value: this.value });
    },
    setFilter(type) {
      if (type === "monthly")
        this.$router.push({
          name: "app-project-monthly",
          params: {
            project_id: this.projectId,
            year_month: moment().format("YYYY-MM"),
          },
        });
      else if (type === "weekly")
        this.$router.push({
          name: "app-project-weekly",
          params: {
            project_id: this.projectId,
            start: moment().startOf("week").format("YYYY-MM-DD"),
            end: moment().endOf("week").format("YYYY-MM-DD"),
          },
        });
      else if (type === "daily")
        this.$router.push({
          name: "app-project-daily",
          params: {
            project_id: this.projectId,
            date: moment().format("YYYY-MM-DD"),
          },
        });
    },
  },
  components: {
    KirbyWeeklyPicker,
    KirbyDatePicker,
    KirbyMonthPicker,
    ProjectUsersModal,
  },
};
</script>

<style lang="css">
.typer .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: #7a38a3 !important;
  color: #fff !important;
}
</style>
