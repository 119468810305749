<template>
  <app-table :loading="loading" :headings="headers" :items="items">
    <template v-slot:actions="{ item }">
      <icon-list icon="mdi-dots-horizontal">
        <v-list dense>
          <v-list-item @click.stop="navigateUser(item)">
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left> mdi-eye-circle-outline </v-icon>
              View Logs
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </icon-list>
    </template>
    <template v-slot:total="{ item }">
      <span>
        {{ item.total || 0 | secToHuman(" hours", " minutes") }}
      </span>
    </template>
    <template v-slot:[`user.real_name`]="{ item }">
      <router-link
        v-if="item.user"
        :to="{
          name: 'app-user-record-monthly',
          params: {
            id: item.user.id,
            year_month: yearMonth,
          },
        }"
      >
        <avatar size="35" :user="item.user" with-name v-if="item.user"></avatar>
      </router-link>
    </template>
    <template v-slot:[`user.email`]="{ item }">
      <span v-if="item.user">{{ item.user.email }}</span>
    </template>
    <template v-slot:[`user.status`]="{ item }">
      <chip-in label v-if="item.user && item.user.status === 'in'"></chip-in>
      <chip-brb
        label
        v-else-if="item.user && item.user.status === 'brb'"
      ></chip-brb>
      <chip-out
        label
        v-else-if="item.user && item.user.status === 'out'"
      ></chip-out>
    </template>
  </app-table>
</template>

<script>
import moment from "moment";
export default {
  props: {
    loading: { type: Boolean, default: false },
    loadingMore: { type: Boolean, default: false },
    items: { type: [Array, Object], default: () => [] },
  },
  data() {
    return {
      headers: [
        {
          text: "User Name",
          value: "user.real_name",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Email Address",
          value: "user.email",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Type",
          value: "project_role",
          sortable: false,
          tdClass: "xtext-left",
        },
        {
          text: "Status",
          value: "user.status",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Work Time",
          value: "total",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          tdClass: "xtext-right",
        },
      ],
    };
  },
  computed: {
    yearMonth() {
      return moment().format("YYYY-MM");
    },
  },
  methods: {
    navigateUser(item) {
      if (!item.user) return;
      this.$router.push({
        name: "app-user-time-monthly",
        params: {
          user_id: item.user.id,
          year_month: moment().format("YYYY-MM"),
          pid: this.$route.params.project_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-clocking {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 10px;
  border-radius: 3px;
  &.status-in {
    color: #13d40f;
    background: rgba(106, 231, 62, 0.14);
  }
  &.status-brb {
    color: #4992ff;
    background: rgba(73, 146, 255, 0.14);
  }
  &.status-out {
    color: #ff4949;
    background: rgba(255, 73, 73, 0.14);
  }
}
</style>
